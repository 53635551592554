import './ChatFrame.css';
import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';
import ChatMembers from './ChatMembers';

function ChatFrame() {
  return (
    <div className="ChatFrame">
      <div id="ChatIO">
        <ChatHistory />
        <ChatInput />
      </div>
      <ChatMembers />
    </div>
  );
}

ChatFrame.defaultProps = {
  height: '100%;',
  width: '100%'
}

export default ChatFrame;
