import './ChatMembers.css';

function ChatMembers() {
  return (
    <div className="ChatMembers">
      <div className="TopBack"/>
      <div className="Self"/>
      <div className="Nicklist"></div>
      <div className="BottomBack"/>
    </div>
  );
}

export default ChatMembers;
