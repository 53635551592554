import './ChatHistory.css';

function ChatHistory() {
  return (
    <div className="ChatHistory" >
      <p style={{ color: 'red' }}>
        <span>
          Welcome to MSNLD.com. We're not quite ready yet.<br />
          Check back soon!
        </span>
      </p>
    </div>
  );
}

export default ChatHistory;
