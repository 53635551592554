import './ChatInput.css';

function ChatInput() {
  return (
    <div className="ChatInput">
      <input type="text"/>
      <input type="submit" disabled value="Send!"/>
      <input type="button" disabled value="!"/>
    </div>
  );
}

export default ChatInput;
